import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./genericactivation.component.css";
// import CatDog from "../../assets/generic_img.jpg";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import Navbar from "../header/header.component";
import { switchUrls } from "../../api/index.js";
import Loader from "../../assets/loader.gif";
const baseURL = switchUrls("generic");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

export default class GenericActivation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "https://storage.googleapis.com/pettag/qr/assets/laptop.png",
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "Laptop",
      valuableTypeToIdMap: {
        Laptop: 1,
        Mobile: 2,
        "Laptop Bag": 3,
        Mouse: 4,
        Camera: 5,
        Watch: 6,
        "Water Bottle": 7,
        Charger: 8,
        "Ladies Purse": 9,
        "Sports Kit Bag": 10,
        "School Bag": 11,
        // Other: 13,
      },
      valuableImages: {
        1: "laptop.png",
        2: "mobile.png",
        3: "laptopbag.png",
        4: "mouse.png",
        5: "camera.png",
        6: "watch.png",
        7: "waterbottle.png",
        8: "charger.png",
        9: "ladiespurse.png",
        10: "sport-bag.png",
        11: "schoolbag.png",
        // 12: "luggage.png",
      },
      valuable_desc: "",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      sidebar: true,
      subMenu: true,
      showLoader: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }

  componentDidMount() {
    console.log(usertoken);
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);

  }

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }
  handleDropdownChange(event) {
    const selectedValuableType = event.target.value;
    const selectedId = this.state.valuableTypeToIdMap[selectedValuableType];
    this.setState({
      valuable_type: selectedValuableType,
      valuable_id: selectedId,
      imageUrlvector: this.state.valuableImages[selectedId],
      // bag_imagelocation: null,
      // imageUrl: null,
      // isuploading: false,
    });
  }
  handleChange(event) {
    if (event.target.files[0]) {
      // var sizeinmb = event.target.files[0].size/(1024*1024)
      // console.log(sizeinmb)
      // if(sizeinmb > 2){
      //     this.setState({message:'File size should be less than 2 MB'})
      // }
      // else{
      this.setState({ message: "" });
      this.setState({
        selectedImage: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
      });
      // }
    }
  }
  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    axios
      .post(`${baseURL}/api/generic/genericactivation`, this.state, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        console.log(response);
        if (response.data === "Tag Already Activated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        } else if (response.data === "Tag not Found by the specified Number") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        } else if (response.data === "Generic Activated Successfully") {
          window.location.href = "/dashboard/" + localStorage.getItem("uid");
          const fd = new FormData();
          fd.append("image", this.state.selectedImage);
          fd.append("tag_number", this.props.match.params.tag_number);
          axios
            .post("/api/generic/genericimageupload", fd)
            .then((res) => {
              console.log(res);
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Generic Activated Successfully",
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        // this.setState({snackbaropen:true,snackbarmsg:'Key Activation Failed'})
      });
  };

  render() {
    const {
      imageUrl,
      tag_number,
      valuable_type,
      valuable_desc,
      sidebar,
      subMenu,
      valuableTypeToIdMap,
      valuableImages,
    } = this.state;
    const formattedValuableType = valuable_type.replace(
      /([a-z])([A-Z])/g,
      "$1 $2"
    );
    console.log(formattedValuableType, "valaube");
    const selectedId = valuableTypeToIdMap[formattedValuableType];
    const selectedImageFilename = valuableImages[selectedId];
    const imageUrlvectors = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
    // console.log(generic_imagelocation, "locationiamage");
    console.log(imageUrl, "purchase date");
    console.log(imageUrlvectors, "vecto");

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />
        <div className="petActivation-main-wrapper">
          <div className="petActivation-left-wrapper">
            <div className="petActivation-sidebar-toggle-btn-wrapper">
              <button
                className="petActivation-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {this.state.sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "petActivation-sidebar-container-hide"
                  : "petActivation-sidebar-container"
              }
            >
              <button
                className="petActivation-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS{" "}
                {subMenu ? (
                  <AiIcons.AiFillCaretUp />
                ) : (
                  <AiIcons.AiFillCaretDown />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "petActivation-sidebar-menu-ul-hide"
                    : "petActivation-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="petActivation-sidebar-menu-ul-links"
                    href={`/dashboard/` + this.props.match.params.tag_number}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="petActivation-sidebar-menu-ul-links"
                    href={`/dashboard/` + this.props.match.params.tag_number}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="petActivation-sidebar-menu-ul-links"
                    href={`/dashboard/` + this.props.match.params.tag_number}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="petActivation-sidebar-menu-link"
                href={`/dashboard/` + this.props.match.params.tag_number}
              >
                ACTIVATE TAG
              </button>

              <button
                className="petActivation-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div>
          <div className="bag-container">
            <div className="background-image">
              <div className="bag-activation-container">
                <p>
                  <strong>Generic Activation</strong>
                </p>

                <div className="bag-activation-form-container">
                  <form
                    onSubmit={this.submitHandler}
                    className="bag-activation-form"
                  >
                    <div className="bag-image-upload">
                      <img alt="" src={imageUrlvectors} className="bag-image" />
                      <div
                        className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                        style={{ gap: "6px" }}
                      >
                        <img
                          src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                          style={{ width: "30px", height: "30px" }}
                        />
                        <p style={{ marginTop: "15px" }}>
                          <b>
                            {tag_number && tag_number !== "-"
                              ? tag_number
                              : "N/A"}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="bagactivation-label-container">
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginTop: "-7px", marginLeft: "13px" }}
                          >
                            Valuable Type
                          </InputLabel>
                          <Select
                            labelId="valuable-type-label"
                            id="valuable-type-select"
                            value={valuable_type}
                            label={"valuable type"}
                            required
                            onChange={this.handleDropdownChange}
                            variant="outlined"
                          >
                            <MenuItem value="">
                              <em>Select Valuable Type</em>
                            </MenuItem>
                            {Object.keys(valuableTypeToIdMap).map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="bagactivation-label-container">
                        <TextField
                          type="text"
                          label="Description"
                          variant="outlined"
                          // className="petactivation-input-tag"
                          name="valuable_desc"
                          value={valuable_desc}
                          onChange={this.changeHandler}
                        />
                      </div>
                      <div className="bagactivation-btn-grp">
                        <Button
                          variant="contained"
                          type="submit"
                          style={{ width: "100%" }}
                          className="submit-login bg-warning text-white mt-2"
                        >
                          SUBMIT
                        </Button>

                        <Button
                          variant="contained"
                          type="button"
                          style={{ width: "100%" }}
                          onClick={() => {
                            window.location.href =
                              "/dashboard/" + localStorage.getItem("uid");
                          }}
                          className="submit-login bg-white mt-2"
                        >
                          CANCEL
                        </Button>
                      </div>
                    </div>
                    {this.state.showLoader && (
                      <div className="loader-container">
                        <div className="loader">
                          <img
                            src={Loader}
                            alt="Loading..."
                            loading="lazy"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
